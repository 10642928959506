.epub-reader {
    flex-grow: 1;
}

.tool-bar {
    height: 50px;
    width: 100%;
    background-color: #f8f8f8;
    position: absolute;
    z-index: 10;
    transition: all .5s ease;
    display: flex;
    justify-content: center;

    .tool {
        display: flex;
        width: 50px;
        margin: 5px;
        align-items: center;
        justify-content: center;

        .bg-color {
            width: 25px;
            height: 25px;
        }

        &.font-up {
            img {
                width: 24px;
            }
        }

        &.font-normal {
            img {
                width: 20px;
            }
        }

        &.font-down {
            img {
                width: 16px;
            }
        }
    }
}
